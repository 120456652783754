<template>
  <component :is='contentComponentBySessionType'
    :show-session-content-select='true'
    :show-session-today-watched-time='true'
    :show-session-title-top='true'
    :show-session-details-short-top='true'
    :show-session-details-full='false'
    :show-session-details-short='false'
    :show-session-topics='true'
    :show-session-speakers='false'
    :show-session-other-sessions='false' />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import LiveSession  from '@/components/sessions/v2/LiveSession.vue'
import VodSession   from '@/components/sessions/VodSession.vue'

export default {
  name: 'Session',
  components: {
    LiveSession,
    VodSession,
  },
  props: {
    needToLogin: {
      default: true,
      type: Boolean
    },
  },
  watch: {
    $route: {
      handler: function (to, from) {
        if (to !== from) {
          if (this.needToLogin) {
            this.needToLoginApiCalls()
          } else {
            this.noLoginRequiredCalls()
          }
          
        }
      },
      immediate: true
    },
  },
  computed: {
    ...mapGetters('sessions', [
      'selectedSessionType'
    ]),
    contentComponentBySessionType () {
      if (this.selectedSessionType === 'vod') {
        return 'vod-session'
      } else if (this.selectedSessionType === '') {
        return 'div'
      } else {
        return 'live-session'
      }
    }
  },
  methods: {
    ...mapActions('sessions', [
      'getCurrentSessionId',
      'getSingleSessionV2',
      'subscribeToGoto',
      'unsubscribeFromGoto',
      'getSingleSessionDetailsNoLoginRequired',
    ]),
    ...mapActions('sessionNotices', [
      'getSessionNotices',
      'subscribeToNotices',
      'unsubscribeFromNotices',
    ]),
    checkCurrentSessionId () {
      this.currentSessionIdChecker = setInterval(() => {
        if (!this.$route.query.session_id) {
          clearInterval(this.currentSessionIdChecker)
        } else {
          this.getCurrentSessionId(this.$route.query.session_id).then((currentSessionId) => {
            if (currentSessionId) {
              // 다음 세션 있을 때
              if (parseInt(this.$route.query.session_id) !== currentSessionId) {
                // 지금 page query랑 다를 때
                this.$alert('This session has ended. Navigating to current session.', {
                  type: 'info',
                  confirmButtonText: 'Go to Current'
                }).then(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}})
                })
                setTimeout(() => {
                  this.$router.replace({name: 'Session', query: {session_id: currentSessionId, autoplay: true}})
                  location.reload(true)
                }, 5000)
              }
            } else {
              // 다음 세션 없을 때
              this.$alert('There are no sessions in progress. Navigating to the programs page.', {
                type: 'info',
                confirmButtonText: 'Go to Programs'
              }).then(() => {
                this.$router.replace({name: 'Programs'})
              })
              setTimeout(() => {
                this.$router.replace({name: 'Programs'})
                location.reload(true)
              }, 5000)
            }
          })
        }
      }, 120000) // call api per 2 minutes
    },
    goToCurrentSession (sessionId) {
      this.getCurrentSessionId(sessionId).then((currentSessionId) => {
        if (currentSessionId) {
          // 다음 세션 있을 때
          let query = {
            session_id: currentSessionId
          }
          if (this.$route.query.autoplay) {
            query.autoplay = this.$route.query.autoplay
          }
          this.$router.replace({name: 'Session', query: query}).catch(() => {})
        } else {
          // 다음 세션 없을 때
          this.$alert('There are no sessions in progress. Navigating to the programs page.', {
            type: 'info',
            confirmButtonText: 'Go to Programs'
          }).then(() => {
            this.$router.replace({name: 'Programs'}).catch(() => {})
          }) 
        }
      })
    },
    needToLoginApiCalls () {
      this.goToCurrentSession(this.$route.query.session_id)
      this.getSingleSessionV2(this.$route.query.session_id)
      this.getSessionNotices(this.$route.query.session_id)
      this.unsubscribeFromNotices()
      this.subscribeToNotices(this.$route.query.session_id)
      this.unsubscribeFromGoto()
      this.subscribeToGoto(this.$route.query.session_id)
      if (this.contentComponentBySessionType === 'live-session') {
        this.checkCurrentSessionId()
      }
    },
    noLoginRequiredCalls () {
      this.getSingleSessionDetailsNoLoginRequired(this.$route.query.session_id)
    },
  },
}
</script>
